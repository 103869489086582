(function ($) {
  'use strict';

  function initStarter() {
  }

  $(function() {
    initStarter();
  });

  $(".popup__show" ).click(function() {
    $(".footer__official__expandable").addClass("hidden");
    $(".popup").removeClass("hidden");
  });

  $(".popup__hide" ).click(function() {
    $(".popup").addClass("hidden");
    $(".footer__official__expandable").removeClass("hidden");
  });

})(window.jQuery);
